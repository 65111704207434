'use client'

import { Fragment, useEffect, useState } from 'react'
import {
  Label,
  Spacer,
  Cell,
  Text,
  Card,
  Dialog,
  Radio,
  Button,
  Divider,
  Icon,
} from '@vinted/web-ui'
import { Dropdown16, Globe24 } from '@vinted/monochrome-icons'

import { FormattedMessage } from 'components/@support'
import { LanguageDto } from 'types/dtos'

import useLanguageSelector from '../useLanguageSelector'

type Props = {
  languages: Array<LanguageDto>
  activeLanguage: LanguageDto
  isStandalone?: boolean
  setActiveLanguage: (language: LanguageDto) => void
}

const LanguageSelectorModal = ({
  languages,
  activeLanguage,
  isStandalone,
  setActiveLanguage,
}: Props) => {
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageDto>(activeLanguage)
  const { isMenuOpen, toggleMenu, saveLanguage } = useLanguageSelector({
    activeLanguage,
    setActiveLanguage,
  })

  useEffect(() => {
    setSelectedLanguage(activeLanguage)
  }, [activeLanguage])

  const handleLanguageSelect = (language: LanguageDto) => () => setSelectedLanguage(language)
  const handleLanguageSubmit = (language: LanguageDto) => () => saveLanguage(language)

  const renderNavigationToggle = () => (
    <>
      <Label
        type={Label.Type.Stacked}
        styling={Label.Styling.Default}
        text={<FormattedMessage id="language_selector.menu_title" />}
      />
      <Cell
        prefix={<Icon name={Globe24} color={Icon.Color.GreyscaleLevel3} />}
        type={Cell.Type.Navigating}
        onClick={toggleMenu}
      >
        <Text
          as="h3"
          text={activeLanguage.title_local}
          type={Text.Type.Subtitle}
          theme="amplified"
        />
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
        <Text as="h3" text={`(${activeLanguage.title})`} type={Text.Type.Subtitle} theme="muted" />
      </Cell>
    </>
  )

  const renderStandaloneToggle = () => (
    <div className="u-ui-padding-left-small">
      <Button
        text={activeLanguage.title_short.toUpperCase()}
        styling={Button.Styling.Flat}
        size={Button.Size.Medium}
        theme="muted"
        inline
        truncated={false}
        icon={<Icon name={Dropdown16} color={Icon.Color.GreyscaleLevel3} />}
        iconPosition={Button.IconPosition.Right}
        onClick={toggleMenu}
      />
    </div>
  )

  return (
    <>
      {isStandalone ? renderStandaloneToggle() : renderNavigationToggle()}
      <Dialog show={isMenuOpen}>
        <Card styling={Card.Styling.Elevated}>
          <Cell>
            <div className="u-text-center">
              <Text
                as="h1"
                text={<FormattedMessage id="language_selector.menu_title" />}
                type={Text.Type.Heading}
              />
            </div>
          </Cell>

          {languages.map(language => {
            const isChecked = language.id === selectedLanguage.id

            return (
              <Fragment key={language.id}>
                <Cell
                  type={Cell.Type.Navigating}
                  onClick={handleLanguageSelect(language)}
                  suffix={
                    <Radio
                      name={`language_selector_${language.id}`}
                      checked={isChecked}
                      onChange={() => undefined}
                    />
                  }
                >
                  <Text as="span" text={language.title_local} theme="amplified" bold />
                  <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
                  <Text as="span" text={`(${language.title})`} bold />
                </Cell>
                <Divider />
              </Fragment>
            )
          })}

          <div className="u-ui-padding-large">
            <Button
              text={<FormattedMessage id="language_selector.menu_options.save" />}
              styling={Button.Styling.Filled}
              onClick={handleLanguageSubmit(selectedLanguage)}
              testId="language-selector-modal-save"
            />
          </div>
          <div className="u-ui-padding-large u-padding-top-none">
            <Button
              text={<FormattedMessage id="language_selector.menu_options.cancel" />}
              styling={Button.Styling.Flat}
              onClick={toggleMenu}
              testId="language-selector-modal-cancel"
            />
          </div>
        </Card>
      </Dialog>
    </>
  )
}

export default LanguageSelectorModal
