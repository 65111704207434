import { withServerSideProxy } from './proxy'

const sessionStorageHelpers = {
  getSessionStorageItem: (key: string) => sessionStorage.getItem(key),
  removeSessionStorageItem: (key: string) => sessionStorage.removeItem(key),
  setSessionStorageItem: (key: string, value: string) => sessionStorage.setItem(key, value),
}

const serverSafeSessionStorageHelpers = withServerSideProxy(sessionStorageHelpers, {
  getSessionStorageItem: () => null,
})

export const { getSessionStorageItem, removeSessionStorageItem, setSessionStorageItem } =
  serverSafeSessionStorageHelpers
