import { AuthenticateUserError } from 'types/api'
import { TwoFaDetailsDto, TwoFaErrorDto } from 'types/dtos'
import { AuthenticateUserErrorModel, TwoFaDetailsModel } from 'types/models'

export const transformTwoFaErrorDetails = ({
  control_code,
  next_resend_available_in,
  user_masked_info,
  show_resend_option,
}: TwoFaErrorDto): TwoFaDetailsModel => ({
  controlCode: control_code,
  nextResendAvailableIn: next_resend_available_in,
  userMaskedInfo: user_masked_info,
  showResendOption: show_resend_option,
})

export const transformTwoFaDetails = ({
  id,
  next_resend_available_in,
  show_resend_option,
  user_masked_info,
}: TwoFaDetailsDto): TwoFaDetailsModel => ({
  controlCode: id,
  nextResendAvailableIn: next_resend_available_in,
  showResendOption: show_resend_option,
  userMaskedInfo: user_masked_info,
})

export const transformAuthenticateUserError = (
  authenticateUserError: AuthenticateUserError,
): AuthenticateUserErrorModel => {
  if ('id' in authenticateUserError) return authenticateUserError

  return transformTwoFaErrorDetails(authenticateUserError)
}
