'use client'

import { Spacer, Text } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'
import { SIGNUP_HELP_ENTRIES_URL } from 'constants/routes'
import { registerUser } from 'data/api/authentication/requests'
import { getFingerprint } from 'libs/utils/fingerprint'

import { ClickableElement } from 'constants/tracking/clickable-elements'

import RegisterForm, { FormData } from '../RegisterForm'
import { useRegistrationTracking } from '../hooks/useRegistrationTracking'
import useAuthTracking from '../hooks/useAuthTracking'

const TRANSLATION_PREFIX = 'auth.email.register'

const EmailRegister = () => {
  const translate = useTranslate(TRANSLATION_PREFIX)
  const trackRegistrationEvents = useRegistrationTracking()
  const { trackClickEvent } = useAuthTracking()

  async function performRegistration(data: FormData) {
    const fingerprint = await getFingerprint()

    const response = await registerUser({
      agreeRules: true,
      password: '',
      ...data,
      fingerprint,
    })

    await trackRegistrationEvents({
      response,
      authType: 'internal',
      email: data.email,
    })

    return response
  }

  function handleHelpClick() {
    trackClickEvent({
      target: ClickableElement.HavingTrouble,
    })
  }

  function renderDescription() {
    return (
      <div className="u-ui-margin-horizontal-large">
        <Text
          as="h1"
          id="auth_modal_title"
          text={translate('title')}
          type={Text.Type.Heading}
          width={Text.Width.Parent}
          alignment={Text.Alignment.Center}
        />
        <Spacer size={Spacer.Size.Large} />
      </div>
    )
  }

  return (
    <>
      {renderDescription()}
      <RegisterForm isPasswordRequired onSubmit={performRegistration} />
      <div className="u-ui-margin-horizontal-large">
        <Text as="span" width={Text.Width.Parent} alignment={Text.Alignment.Center}>
          <a href={SIGNUP_HELP_ENTRIES_URL} onClick={handleHelpClick}>
            {translate('actions.help')}
          </a>
        </Text>
        <Spacer size={Spacer.Size.XLarge} />
      </div>
    </>
  )
}

export default EmailRegister
