import { API_BASE_PATH } from 'constants/paths'
import ApiClient, { interceptors } from 'libs/common/api-client'
import * as apiTypes from 'types/api'

const api = new ApiClient({}, [
  interceptors.csrfTokenInterceptor,
  interceptors.errorInterceptor,
  interceptors.localeInterceptor,
])

export function resetPassword({ email }: apiTypes.ResetPasswordArgs) {
  return api.post(`${API_BASE_PATH}/users/reset_password`, {
    email,
  })
}
