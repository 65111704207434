'use client'

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { InputText, Button, Card, Cell, Spacer, Text, Icon } from '@vinted/web-ui'

import { CheckCircleFilled24 } from '@vinted/monochrome-icons'

import { renderValidation } from 'components/Input'
import useFormValidationMessage from 'hooks/useFormValidationMessage'
import useTranslate from 'hooks/useTranslate'
import { resetPassword } from 'data/api/account'
import useDataDomeCaptcha from 'hooks/useDataDomeCaptcha'

type FormData = {
  email: string
}

const TRANSLATION_PREFIX = 'auth.forgot_password'

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm<FormData>()

  const translate = useTranslate(TRANSLATION_PREFIX)
  const getErrorMessage = useFormValidationMessage(errors, `${TRANSLATION_PREFIX}.fields`)

  const [showSuccess, setShowSuccess] = useState(false)

  async function handleFormSubmit({ email }: FormData) {
    await resetPassword({ email })

    setShowSuccess(true)
  }

  useDataDomeCaptcha(() => {
    handleFormSubmit(getValues())
  })

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="u-ui-padding-horizontal-large">
        <Text
          as="h1"
          id="auth_modal_title"
          text={translate('title')}
          type={Text.Type.Heading}
          width={Text.Width.Parent}
          alignment={Text.Alignment.Center}
        />
      </div>
      <Spacer size={Spacer.Size.Large} />
      {showSuccess && (
        <div className="u-ui-padding-horizontal-large">
          <Card>
            <Cell
              testId="reset-password-success-note"
              prefix={<Icon name={CheckCircleFilled24} color={Icon.Color.Success} />}
              body={translate('success_note')}
            />
          </Card>
        </div>
      )}
      <Spacer />
      <InputText
        {...register('email', {
          required: true,
        })}
        placeholder={translate('fields.email.title')}
        validation={renderValidation(getErrorMessage('email'))}
      />
      <Cell>
        <Button
          text={translate('actions.submit')}
          type={Button.Type.Submit}
          styling={Button.Styling.Filled}
          disabled={isSubmitting}
          isLoading={isSubmitting}
          onClick={(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            if (isSubmitting) {
              event.preventDefault()
            }
          }}
        />
      </Cell>
    </form>
  )
}

export default ResetPassword
