import { useCallback } from 'react'

import { AuthenticateGrantType, AuthenticateProvider } from 'constants/auth'
import { authenticateUser } from 'data/api/authentication/requests'
import { getFingerprint } from 'libs/utils/fingerprint'
import useTranslate from 'hooks/useTranslate'

import { isBlockedByDataDome, isNetworkError } from '../utils'

const useSocialLogin = () => {
  const translate = useTranslate('auth.select_type')

  const loginWithSocial = useCallback(
    async (provider: AuthenticateProvider, token: string) => {
      const fingerprint = await getFingerprint()

      const response = await authenticateUser({
        grantType: AuthenticateGrantType.Assertion,
        assertion: token,
        provider,
        fingerprint,
      })

      if (isNetworkError(response)) {
        response.message = translate('errors.offline')
      }

      if (isBlockedByDataDome(response)) {
        response.message = translate('errors.datadome')
      }

      return response
    },
    [translate],
  )

  return loginWithSocial
}

export default useSocialLogin
