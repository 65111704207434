'use client'

import { isEmpty } from 'lodash'
import { Image } from '@vinted/web-ui'

import { EMPTY_USER_IMAGE_NAME } from 'constants/images'
import useAsset from 'hooks/useAsset'

type OwnProps = {
  isSystem?: boolean
}

type Props = OwnProps & ComponentProps<typeof Image>

const UserImage = (props: Props) => {
  const { src, isSystem } = props
  const asset = useAsset('assets/no-photo')

  const srcPath = isEmpty(src) ? asset(EMPTY_USER_IMAGE_NAME) : src
  const imgSrc = isSystem ? asset('user-clothing/system-photo/default.png') : srcPath

  return <Image {...props} src={imgSrc} alt={props.alt || ''} />
}

export { UserImage }

export default UserImage
