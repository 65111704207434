const DEVICE_OS_ANDROID = 'Android'
const DEVICE_OS_IOS = 'iOS'
const DEVICE_OS_WINDOWS_PHONE = 'Windows Phone'
const DEVICE_OS_MAC = 'MacOS'

export const DEVICE_IPHONE = 'iphone'
export const DEVICE_ANDROID = 'android'

export function parseOS(userAgent: string): string | null | undefined {
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return DEVICE_OS_WINDOWS_PHONE
  }

  if (/android/i.test(userAgent)) {
    return DEVICE_OS_ANDROID
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return DEVICE_OS_IOS
  }

  if (/Macintosh/.test(userAgent)) {
    return DEVICE_OS_MAC
  }

  return null
}

export function parseDevice(userAgent: string): string | undefined {
  const os = parseOS(userAgent)

  if (os === DEVICE_OS_ANDROID) {
    return DEVICE_ANDROID
  }
  if (os === DEVICE_OS_IOS) {
    return DEVICE_IPHONE
  }

  return undefined
}

const isIOS = (userAgent: string) => parseOS(userAgent) === DEVICE_OS_IOS
const isAndroid = (userAgent: string) => parseOS(userAgent) === DEVICE_OS_ANDROID
const isMobile = (userAgent: string) => {
  return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
    userAgent,
  )
}
const isMacOS = (userAgent: string) => parseOS(userAgent) === DEVICE_OS_MAC

export default parseOS
export { isIOS, isAndroid, isMobile, isMacOS }
