// TODO: change `Record<string, any>` to `Record<string, unknown>`
export const decodeJwt = <T extends Record<string, any>>(token: string): Partial<T> => {
  const splittedToken = token.split('.')

  if (splittedToken.length < 2) return {}

  const [, payload] = splittedToken
  const base64 = payload!.replace(/-/g, '+')?.replace(/_/g, '/')

  const decoded = atob(base64)

  if (decoded.length === 1 || decoded.length === 0) return {}

  const jsonArgs = decodeURIComponent(
    decoded
      .split('')
      .map(char => `%${`00${char.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  )

  try {
    return JSON.parse(jsonArgs)
  } catch {
    return {}
  }
}

export const decodeBase64Object = <T>(base64String: string): T | undefined => {
  try {
    const decodedString = atob(base64String)

    return JSON.parse(decodedString)
  } catch {
    return undefined
  }
}
