'use client'

import { Children, Component, ReactNode } from 'react'
import { compact } from 'lodash'

type Props = {
  children?: Array<ReactNode>
}

class List extends Component<Props> {
  // TODO: convert into functional component
  // eslint-disable-next-line class-methods-use-this
  renderChild = (child: ReactNode) => <li className="pile__element">{child}</li>

  render() {
    const { children } = this.props

    if (!children) return null

    return <ul className="pile">{Children.map(compact(children), this.renderChild)}</ul>
  }
}

export default List
