import { useState } from 'react'

import { isResponseSuccessful } from 'libs/utils/api'
import { changeLanguageEvent } from 'libs/common/event-tracker/events'

import useTracking from 'hooks/useTracking'

import { removeLocaleUrlParam } from 'components/LanguageSelector/utils'
import useLocation from 'hooks/useLocation'

import { updateUserLanguage } from 'data/api'
import { redirectToPage } from 'libs/utils/window'
import { LanguageDto } from 'types/dtos'
import useCookie from 'libs/common/cookie-manager/hooks/useCookie'
import { cookiesDataByName } from 'libs/common/cookie-manager/cookies-data'
import useSession from 'hooks/useSession'

type Props = {
  activeLanguage: LanguageDto
  setActiveLanguage: (language: LanguageDto) => void
}

const useLanguageSelector = ({ activeLanguage, setActiveLanguage }: Props) => {
  const { user, screen } = useSession()
  const userId = user?.id

  const cookies = useCookie()

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { relativeUrl, urlQuery } = useLocation()
  const { track } = useTracking()

  const hideMenu = () => {
    setIsMenuOpen(false)
  }

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState)
  }

  const saveLanguage = async (language: LanguageDto) => {
    hideMenu()

    track(
      changeLanguageEvent({
        fromLanguage: activeLanguage?.code || null,
        toLanguage: language.code,
        screen,
      }),
    )

    if (userId) {
      const response = await updateUserLanguage({ userId, locale: language.code })

      if (!isResponseSuccessful(response)) return
    } else {
      cookies.set(cookiesDataByName['anonymous-locale'], language.code)
    }

    setActiveLanguage(language)

    cookies.delete(cookiesDataByName.locale)
    redirectToPage(removeLocaleUrlParam(relativeUrl, urlQuery))
  }

  return {
    isMenuOpen,
    toggleMenu,
    hideMenu,
    saveLanguage,
  }
}

export default useLanguageSelector
