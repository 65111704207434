import { ReferrerDto } from 'types/dtos'
import { ReferrerModel } from 'types/models'

export const transformReferrerDto = ({
  referrer_id,
  login,
  photo_url,
  feedback_count,
  feedback_reputation,
  is_business,
  business_account_name,
}: ReferrerDto): ReferrerModel => ({
  referrerId: referrer_id,
  login,
  photoUrl: photo_url,
  feedbackReputation: feedback_reputation,
  feedbackCount: feedback_count,
  isBusiness: is_business,
  businessAccountName: business_account_name,
})
