import { GOOGLE_MAPS_SERVICE_ID, GOOGLE_SDK_LINK, GOOGLE_SDK_SCRIPT_ID } from 'constants/google'

import { decodeBase64Object } from './authentication'
import { loadScript } from './script'

type InjectScriptProps = {
  apiKey: string
  onGoogleApiLoaded: () => void
}

let googleApiLoadedCallbacks: Array<() => void> = []

const callGoogleApiCallbacks = () => {
  googleApiLoadedCallbacks.forEach(callback => callback())
}

const onGoogleCallback = (onGoogleApiLoaded: () => void) => {
  window.googleApiLoaded = callGoogleApiCallbacks

  if (!googleApiLoadedCallbacks.includes(onGoogleApiLoaded)) {
    googleApiLoadedCallbacks.push(onGoogleApiLoaded)
  }

  if (!window.google?.maps) return

  callGoogleApiCallbacks()
}

export const loadGoogleApi = ({ apiKey, onGoogleApiLoaded }: InjectScriptProps) => {
  onGoogleCallback(onGoogleApiLoaded)

  if (document.getElementById(GOOGLE_MAPS_SERVICE_ID)) {
    return
  }

  const url = new URL('/maps/api/js', 'https://maps.googleapis.com')
  url.searchParams.set('key', apiKey)
  url.searchParams.set('libraries', ['places', 'geometry'].toString())
  url.searchParams.set('callback', 'googleApiLoaded')

  const src = url.toString()

  loadScript({ id: GOOGLE_MAPS_SERVICE_ID, src })
}

export const loadGoogleSdk = () => {
  return loadScript({
    id: GOOGLE_SDK_SCRIPT_ID,
    isAsync: true,
    src: GOOGLE_SDK_LINK,
  })
}

export const removeGoogleCallback = (callback: () => void) => {
  googleApiLoadedCallbacks = googleApiLoadedCallbacks.filter(
    loadedCallback => loadedCallback !== callback,
  )
}

export const getGoogleRedirectUrl = (state: string) => {
  const decodedState = decodeBase64Object<{ redirect_uri: string; random_string: string }>(state)

  return decodedState?.redirect_uri || state
}
